import { graphql } from 'gatsby';
import { getImage } from 'gatsby-plugin-image';
import { frontmatter } from '../helper/data';

function cmsTeaserProps(teaser) {
  return {
    heading: teaser.heading,
    text: teaser.text,
    components: {
      image: 'img'
    },
    componentsProps: {
      link: {
        to: teaser.link?.target,
        children: teaser.link?.name,
        color: 'onDark'
      },
      image: {
        src: teaser.image?.file ?? '/img/placeholder-image-wide.png',
        alt: teaser.image?.altText
      }
    }
  };
}

function appTeaserProps(teaser) {
  return {
    heading: teaser.heading,
    text: teaser.text,
    componentsProps: {
      link: {
        to: teaser.link.target,
        children: teaser.link.name,
        color: 'onDark'
      },
      image: {
        image: getImage(teaser.image.file),
        alt: teaser.image.altText
      }
    }
  };
}

export function mapToProps(data = [], isCms = false) {
  const fmData = isCms ? data : frontmatter(data);
  const contentTeasersData = fmData.contentTeasers || [];

  if (contentTeasersData.length <= 0) {
    return [];
  }

  return contentTeasersData.map(teaser => {
    if (!teaser) {
      return {
        heading: '',
        text: ''
      };
    }
    return isCms ? cmsTeaserProps(teaser) : appTeaserProps(teaser);
  });
}

export const frontmatterFragment = graphql`
  fragment ContentTeasers on MarkdownRemarkFrontmatter {
    contentTeasers {
      heading
      text
      link {
        name
        target
      }
      image {
        altText
        file {
          childImageSharp {
            gatsbyImageData(layout: CONSTRAINED)
          }
        }
      }
    }
  }
`;
