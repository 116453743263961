import { graphql } from 'gatsby';
import { frontmatter } from '../helper/data';
import { markdownToHtml } from '../helper/transformation';

export function mapToProps(data = {}, isCms = false) {
  const fmData = isCms ? data : frontmatter(data);
  const textMarkup = fmData.anchorText
    ? markdownToHtml(fmData.anchorText, ['a'])
    : '';

  return {
    textMarkup: textMarkup
  };
}

export const fragment = graphql`
  fragment AnchorText on MarkdownRemarkFrontmatter {
    anchorText
  }
`;
