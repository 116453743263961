import React from 'react';
import PropTypes from 'prop-types';
import TopBar from '../../organisms/TopBar';
import Main from '../../organisms/Main';
import AnchorText from '../../molecules/AnchorText';
import ShoutingTeaserSection from '../../organisms/ShoutingTeaserSection';
import Footer from '../../organisms/Footer';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(() => ({
  topbar: {},
  anchorText: {}
}));

const IndexPage = props => {
  const { teasersData, TopBarProps, AnchorTextProps, FooterProps } = props;
  const classes = useStyles(props);

  return (
    <>
      <TopBar className={classes.topbar} {...TopBarProps} isLight onHomePage />
      <Main>
        <AnchorText className={classes.anchorText} {...AnchorTextProps} />
        <ShoutingTeaserSection
          className={classes.shoutingTeaserSection}
          teasersData={teasersData}
        />
      </Main>
      <Footer {...FooterProps} />
    </>
  );
};

IndexPage.propTypes = {
  teasersData: PropTypes.arrayOf(PropTypes.object).isRequired,
  TopBarProps: PropTypes.object,
  AnchorTextProps: PropTypes.object,
  FooterProps: PropTypes.object
};

export default IndexPage;
