import React from 'react';
import PropTypes from 'prop-types';
import Image from '../../atoms/Image';
import DummyImage from '../../atoms/DummyImage';
import Link from '../../atoms/Link';
import Grid from '../../base/Layout/Grid';
import EastRounded from '../../base/Icons/EastRounded';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import { shorten, capitalize } from '../../../utils';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  root: {
    position: 'relative',
    '& $image': {
      gridColumn: '1/-1',
      margin: [[0, theme.grid.xs.inset * -1]],
      width: `calc(100% + ${theme.grid.xs.inset * 2}px)`,
      minHeight: '150px'
    },
    '& $box': {
      gridColumn: '1/-1',
      margin: [[0, theme.grid.xs.inset * -1]],
      backgroundColor: theme.palette.primary.dark,
      color: theme.palette.text.lightLowContrast,
      padding: theme.inset.small,
      display: 'flex',
      justifyContent: 'space-between'
    },
    '& $boxContent': {
      flex: 1,
      marginRight: theme.inline.small
    },
    '& $heading': {
      marginBottom: theme.stack.xsmall
    },
    '& $icon': {
      flex: [[0, 0, 'auto']],
      alignSelf: 'flex-end'
    },
    '& $linkSurface': {
      position: 'absolute',
      display: 'block',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      '@media(hover:hover)': {
        '&:hover': {
          backgroundColor: theme.palette.action.hover,
          color: 'transparent',
          opacity: '100%'
        }
      }
    },
    [theme.breakpoints.up('sm')]: {
      '& $image': {
        margin: [[0, theme.grid.sm.inset * -1]],
        width: `calc(100% + ${theme.grid.sm.inset * 2}px)`
      },
      '& $box': {
        margin: [[0, theme.grid.sm.inset * -1]]
      }
    },
    [theme.breakpoints.up('md')]: {
      '& $image': {
        margin: [[0, 0, 0, theme.grid.md.inset * -1]],
        width: `calc(100% + ${theme.grid.md.inset * 2}px)`,
        gridRow: '1/3'
      },
      '& $box': {
        padding: theme.inset.medium,
        gridRow: 2,
        zIndex: 1
      },
      '&$boxAlignLeft': {
        '& $box': {
          margin: [[0, 0, 0, theme.grid.md.inset * -1]],
          gridColumn: '1/-2'
        }
      },
      '&$boxAlignRight': {
        '& $box': {
          margin: [[0, theme.grid.md.inset * -1, 0, 0]],
          gridColumn: '2/-1'
        }
      }
    },
    [theme.breakpoints.up('lg')]: {
      '& $box': {
        padding: [[theme.inset.large, theme.inset.medium]]
      },
      '&$boxAlignLeft': {
        '& $box': {
          gridColumn: '1/-4'
        }
      },
      '&$boxAlignRight': {
        '& $box': {
          margin: [[0, theme.grid.md.inset * -1, 0, 0]],
          gridColumn: '4/-1'
        }
      },
      '& $heading': {
        marginBottom: theme.stack.small
      }
    }
  },
  image: {},
  box: {},
  boxAlignLeft: {},
  boxAlignRight: {},
  boxContent: {},
  heading: {},
  text: {},
  linkSurface: {},
  icon: {}
}));

const ShoutingTeaser = props => {
  const {
    componentsProps,
    components,
    heading,
    text,
    boxAlign,
    className
  } = props;
  const classes = useStyles(props);
  const ImageComponent = components.image ?? Image;
  const HeadingComponent = components.heading ?? 'h3';
  const TextComponent = components.text ?? 'p';
  const LinkComponent = components.link ?? Link;

  return (
    <Grid
      className={clsx(
        classes.root,
        [classes[`boxAlign${capitalize(boxAlign)}`]],
        className
      )}
      component="section"
    >
      {componentsProps.image ? (
        <ImageComponent {...componentsProps.image} className={classes.image} />
      ) : (
        <DummyImage type="2_1" className={classes.image} />
      )}
      <div className={classes.box}>
        <div className={classes.boxContent}>
          {heading && (
            <Typography
              className={classes.heading}
              component={HeadingComponent}
              variant="h3"
            >
              {heading}
            </Typography>
          )}
          {text && (
            <Typography
              className={classes.text}
              component={TextComponent}
              variant="body1"
            >
              {shorten(text, 120)}
            </Typography>
          )}
        </div>

        <EastRounded className={classes.icon} fontSize="large" />
        <LinkComponent
          {...componentsProps.link}
          className={classes.linkSurface}
        />
      </div>
    </Grid>
  );
};

ShoutingTeaser.propTypes = {
  componentsProps: PropTypes.shape({
    image: PropTypes.object,
    link: PropTypes.object,
    text: PropTypes.object,
    heading: PropTypes.object
  }),
  components: PropTypes.shape({
    image: PropTypes.elementType,
    link: PropTypes.elementType,
    text: PropTypes.elementType,
    heading: PropTypes.elementType
  }),
  heading: PropTypes.string.isRequired,
  text: PropTypes.string,
  boxAlign: PropTypes.oneOf(['right', 'left']),
  className: PropTypes.string
};

ShoutingTeaser.defaultProps = {
  boxAlign: 'right',
  componentsProps: {},
  components: {
    image: Image,
    link: Link,
    heading: 'h3',
    text: 'p'
  }
};

export default ShoutingTeaser;
