import React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';
import { makeStyles } from '@material-ui/styles';

const usetStyles = makeStyles(() => ({
  root: {}
}));

const Image = props => {
  const classes = usetStyles(props);

  return <GatsbyImage className={classes.root} {...props} />;
};

export default Image;
