import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import Container from '@material-ui/core/Container';
import { gridSpanCalc } from '../../base/Layout/Grid';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useClasses = makeStyles(theme => ({
  root: {
    paddingTop: theme.inset.medium,
    paddingBottom: theme.inset.medium,
    '& $text': {
      color: theme.palette.text.main,
      '&> a': {
        textDecoration: 'underline',
        color: theme.palette.text.main,
        '@media(hover:hover)': {
          '&:hover': {
            color: theme.palette.secondary.main
          }
        }
      }
    },
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.inset.large,
      paddingBottom: theme.inset.large,
      '& $text': {
        margin: [[0, gridSpanCalc(theme, 'md', 1)]]
      }
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.inset.xlarge,
      paddingBottom: theme.inset.xlarge,
      '& $text': {
        margin: [[0, gridSpanCalc(theme, 'lg', 1)]]
      }
    }
  },
  text: {}
}));

const AnchorText = props => {
  const { textMarkup, className } = props;
  const classes = useClasses(props);

  return (
    <Container className={clsx(classes.root, className)}>
      <Typography
        classes={{ root: classes.text }}
        variant="h2"
        dangerouslySetInnerHTML={{ __html: textMarkup }}
      />
    </Container>
  );
};

AnchorText.propTypes = {
  className: PropTypes.string,
  textMarkup: PropTypes.string
};

AnchorText.defaultProps = {};

export default AnchorText;
