import React from 'react';
import PropTypes from 'prop-types';
import Section from '../../molecules/Section';
import ShoutingTeaser from '../ShoutingTeaser';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {},
  teaser: {}
}));

const ShoutingTeaserSection = props => {
  const { heading, teasersData, className } = props;
  const classes = useStyles(props);

  return (
    <Section className={clsx(classes.root, className)} heading={heading}>
      {teasersData.map((teaserProps, index) => (
        <ShoutingTeaser
          key={index}
          className={classes.teaser}
          boxAlign={index % 2 === 0 ? 'right' : 'left'}
          {...teaserProps}
        />
      ))}
    </Section>
  );
};

ShoutingTeaserSection.propTypes = {
  heading: Section.propTypes.heading,
  teasersData: PropTypes.arrayOf(PropTypes.object),
  className: PropTypes.string
};

export default ShoutingTeaserSection;
