import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import IndexPageTemplate from '../../components/templates/IndexPage';
import Meta from '../../components/Meta.js';
import { BreadcrumbJsonLd, JsonLd } from 'gatsby-plugin-next-seo';
import withRoot from '../../hoc/withRoot';
import { mapToProps as mapToMetaProps } from '../utils/mapper/meta';
import { mapToProps as mapToAnchorTextProps } from '../utils/mapper/anchortext';
import { mapToProps as mapToShoutingTeasersProps } from '../utils/mapper/contentTeasers';
import { mapToProps as mapToTopbarProps } from '../utils/mapper/topbar';
import { getProps as footerProps } from '../utils/mapper/footer';
import { siteMetadata } from '../utils/helper/data';

const IndexPage = props => {
  const { location, data } = props;
  const smData = siteMetadata(data);

  return (
    <>
      <Meta {...mapToMetaProps(data, location)} titleTemplate="%s" />
      <BreadcrumbJsonLd
        itemListElements={[
          {
            position: 1,
            name: smData.title,
            item: smData.siteUrl
          }
        ]}
      />
      <JsonLd
        json={{
          '@context': 'http://schema.org/',
          '@type': 'ResearchProject',
          address: {
            '@type': 'PostalAddress',
            addressCountry: 'DE',
            addressLocality: 'Freiburg, Germany',
            postalCode: '79104',
            streetAddress:
              'Albertstraße 21, 2nd floor, rooms 02 002, 02 005, 02 004'
          },
          memberOf: 'University of Freiburg',
          name: smData.title,
          email: 'claudia.jessen-trefzer@pharmazie.uni-freiburg.de',
          telephone: '+497612036039',
          slogan: 'old bugs - novel drugs',
          url: smData.siteUrl,
          logo: smData.siteUrl + smData.logo
        }}
      />
      <IndexPageTemplate
        teasersData={mapToShoutingTeasersProps(data)}
        TopBarProps={mapToTopbarProps(data, location)}
        AnchorTextProps={mapToAnchorTextProps(data)}
        FooterProps={footerProps()}
      />
    </>
  );
};

IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired
};

export default withRoot(IndexPage);

export const pageQuery = graphql`
  query IndexPageTemplate {
    site {
      siteMetadata {
        title
        siteUrl
        logo
        ...MetaSiteMetadata
      }
    }

    markdownRemark(frontmatter: { templateKey: { eq: "indexPage" } }) {
      frontmatter {
        ...MetaFrontMatter
        ...AnchorText
        ...ContentTeasers
      }
    }
    ...TopBar
  }
`;
