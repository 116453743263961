import React from 'react';
import PropTypes from 'prop-types';
import svg2_1 from '../../../img/placeholder/2_1.svg';
import svg3_1 from '../../../img/placeholder/3_1.svg';
import svg3_2 from '../../../img/placeholder/3_2.svg';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';

const useStyle = makeStyles(() => {
  const ratioBefore = (w, h) => ({
    paddingTop: `${(h / w) * 100}%`
  });
  return {
    root: {
      '&::before': {
        content: '""',
        width: 1,
        marginLeft: -1,
        float: 'left',
        height: 0
      },
      '&::after': {
        content: '""',
        display: 'table',
        clear: 'both'
      },
      '&$ratio3_1::before': ratioBefore(3, 1),
      '&$ratio3_2::before': ratioBefore(3, 2),
      '& $img': {
        display: 'block',
        width: '100%',
        height: '100%'
      }
    },
    img: {},
    ratio3_1: {},
    ratio3_2: {}
  };
});
const DummyImage = props => {
  const { type, className } = props;
  const classes = useStyle(props);
  let imgData;

  switch (type) {
    case '2_1':
      imgData = svg2_1;
      break;
    case '3_1':
      imgData = svg3_1;
      break;
    case '3_2':
      imgData = svg3_2;
      break;
  }

  return (
    <div className={clsx(classes.root, [classes[`ratio${type}`]], className)}>
      <Fade in={true}>
        <img className={classes.img} loading="lazy" src={imgData} alt="Dummy" />
      </Fade>
    </div>
  );
};

DummyImage.propTypes = {
  type: PropTypes.oneOf(['3_1', '3_2']),
  className: PropTypes.string
};

DummyImage.defaultProps = {
  type: '3_2'
};

export default DummyImage;
