import React from 'react';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';

const useStyles = makeStyles(() => ({
  root: {}
}));

const Section = props => {
  const { heading, headingLevel, children, className } = props;
  const classes = useStyles(props);

  return (
    <section className={clsx(classes.root, className)}>
      {heading && (
        <Typography component={`h${headingLevel}`} variant="h3">
          {heading}
        </Typography>
      )}
      {children}
    </section>
  );
};

Section.propTypes = {
  children: PropTypes.node.isRequired,
  heading: PropTypes.string,
  headingLevel: PropTypes.oneOf([1, 2, 3, 4, 5, 6]),
  className: PropTypes.string
};

Section.defaultProps = {
  headingLevel: 1
};

export default Section;
